import { render, staticRenderFns } from "./SchoolLeaveAssignmentPage.vue?vue&type=template&id=3a979255&scoped=true&"
import script from "./SchoolLeaveAssignmentPage.vue?vue&type=script&lang=js&"
export * from "./SchoolLeaveAssignmentPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a979255",
  null
  
)

export default component.exports