<template>
  <StaffActivePageTableControl></StaffActivePageTableControl>
</template>

<script>
import StaffActivePageTableControl from "@/modules/school/components/staff/filter/StaffActivePageTableControl";
export default {
  name: "SchoolStaffLeaveControl",
  components: { StaffActivePageTableControl },
};
</script>

<style scoped></style>
