<template>
  <AppTableControl
    no-filter
    @query-debounced="searchName"
    @search-clicked="$store.dispatch('fetchStaffList')"
    @export-excel-clicked="
      $store.dispatch('downloadStaffListReport', { type: 1 })
    "
    @export-pdf-clicked="
      $store.dispatch('downloadStaffListReport', { type: 2 })
    "
  >
  </AppTableControl>
</template>

<script>
import AppTableControl from "@/modules/core/components/shared/filter/AppTableControl";
export default {
  name: "StaffActivePageTableControl",
  components: { AppTableControl },
  beforeDestroy() {
    this.$store.dispatch("changeStaffListNameFilter", {
      name: null,
    });
  },
  methods: {
    /**
     * Search
     * @param name
     */
    searchName(name) {
      console.log("Name", name);

      this.$store.dispatch("changeStaffListNameFilter", {
        name: name,
      });

      this.$store.dispatch("fetchStaffList");
    },
  },
};
</script>

<style scoped></style>
