<template>
  <div>
    <SchoolLeaveAssignmentTableControl
      class="mb-5"
    ></SchoolLeaveAssignmentTableControl>

    <AppPageCard>
      <template #card-title> Leave Assignment </template>
      <template #card-toolbar>
        <!-- begin: b-nav tabs  -->
        <b-nav pills class="nav-light-primary font-weight-bolder">
          <b-nav-item to="teacher" exact exact-active-class="active"
            >Teacher
          </b-nav-item>
          <b-nav-item to="staff" exact exact-active-class="active"
            >Staff
          </b-nav-item>
        </b-nav>
        <!-- end: b-nav tabs  -->

        <!-- begin: refresh button  -->
        <b-button
          variant="primary"
          class="btn btn-icon btn-light btn-hover-primary ml-4"
          v-on:click="refresh"
        >
          <span class="svg-icon svg-icon-2x svg-icon-primary">
            <inline-svg src="/media/svg/icons/Media/Repeat.svg" />
          </span>
        </b-button>
        <!-- end: refresh button  -->
      </template>

      <!-- begin: router view  -->
      <div class="mt-5">
        <router-view></router-view>
      </div>
      <!-- end: router view  -->
    </AppPageCard>
  </div>
</template>

<script>
import AppPageCard from "@/modules/core/components/shared/card/AppPageCard";
import SchoolLeaveAssignmentTableControl from "@/modules/school/components/management/leave/assignment/control/SchoolLeaveAssignmentTableControl";

export default {
  name: "SchoolLeaveAssignmentPage",
  components: { SchoolLeaveAssignmentTableControl, AppPageCard },
  methods: {
    refresh() {},
  },
};
</script>

<style scoped></style>
