<template>
  <AppTableControl
    no-filter
    @query-debounced="searchName"
    @export-excel-clicked="
      $store.dispatch('downloadTeacherListReport', { type: 1 })
    "
    @export-pdf-clicked="
      $store.dispatch('downloadTeacherListReport', { type: 2 })
    "
  >
    <template #filters>
      <div class="d-flex">
        <!-- begin: Code     -->
        <b-form-group
          class="mr-4"
          label-class="font-weight-bolder"
          label="Code"
        >
          <b-form-input
            v-model="code"
            class="form-control h-auto"
            @input="filterDate"
          >
          </b-form-input>
        </b-form-group>

        <!-- begin: Email     -->
        <b-form-group
          class="mr-4"
          label-class="font-weight-bolder"
          label="Email"
        >
          <b-form-input
            v-model="email"
            class="form-control h-auto"
            @input="filterDate"
          >
          </b-form-input>
        </b-form-group>
      </div>
    </template>
  </AppTableControl>
</template>

<script>
import AppTableControl from "@/modules/core/components/shared/filter/AppTableControl";
export default {
  name: "TeacherActivePageTableControl",
  components: { AppTableControl },
  data() {
    return {
      from_date: null,
      email: null,
      code: null,
    };
  },
  beforeDestroy() {
    this.$store.dispatch("changeTeacherListNameFilter", {
      name: null,
    });
  },
  methods: {
    /**
     * Filter date from input.
     *
     * @param input
     */
    filterDate(input) {
      console.log("Input ", input);
    },

    /**
     * Search
     * @param name
     */
    searchName(name) {
      console.log("Name", name);

      this.$store.dispatch("changeTeacherListNameFilter", {
        name: name,
      });

      this.$store.dispatch("fetchTeacherList");
    },
  },
};
</script>

<style scoped></style>
