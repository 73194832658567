<template>
  <TeacherActivePageTableControl></TeacherActivePageTableControl>
</template>

<script>
import TeacherActivePageTableControl from "@/modules/school/components/teacher/filter/TeacherActivePageTableControl";
export default {
  name: "SchoolTeacherLeaveControl",
  components: { TeacherActivePageTableControl },
};
</script>

<style scoped></style>
